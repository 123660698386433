import React, { Component } from "react";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Experience from "./components/Experience";
import Skills from "./components/Skills";

class App extends Component {

  constructor(props) {
    super();
  }
  render() {
    return (
      <div>
        <Header />
        <About />
        <Skills />
        <Experience />
        <Footer />
      </div>
    );
  }
}

export default App;
