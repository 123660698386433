import React, { Component } from "react";

class Skills extends Component {
  render() {
    var sectionName = 'SKILLS';
    let skillList = [
      {
        "name": "HTML 5",
        "class": "devicon-html5-plain",
        "level": "95"
      },
      {
        "name": "CSS 3",
        "class": "devicon-css3-plain",
        "level": "95"
      },
      {
        "name": "React",
        "class": "devicon-react-original",
        "level": "80"
      },
      {
        "name": "Angular",
        "class": "devicon-angularjs-plain",
        "level": "80"
      },
      {
        "name": "TypeScript",
        "class": "devicon-typescript-plain",
        "level": "90"
      },
      {
        "name": "JavaScript",
        "class": "devicon-javascript-plain",
        "level": "70"
      },
      {
        "name": "Node JS",
        "class": "devicon-nodejs-plain",
        "level": "70"
      },
      {
        "name": "Python",
        "class": "devicon-python-plain",
        "level": "70"
      },
      {
        "name": "GraphQL",
        "class": "devicon-graphql-plain",
        "level": "70"
      },
      {
        "name": "Redis",
        "class": "devicon-redis-plain",
        "level": "70"
      },
      {
        "name": "Mongo DB",
        "class": " devicon-mongodb-plain",
        "level": "70"
      },
      {
        "name": "MySQL",
        "class": "devicon-mysql-plain",
        "level": "70"
      },
      {
        "name": "AWS",
        "class": "devicon-amazonwebservices-plain-wordmark",
        "level": "75"
      },
      {
        "name": "Terraform",
        "class": "devicon-terraform-plain",
        "level": "75"
      },
    ];
    var skills = skillList.map(function (skills, i) {
      return (
        <li className="list-inline-item mx-3" key={i}>
          <span>
            <div className="text-center skills-tile">
              <i className={skills.class} style={{ fontSize: "220%" }}>
                <p
                  className="text-center"
                  style={{ fontSize: "30%", marginTop: "4px" }}
                >
                  {skills.name}
                </p>
              </i>
            </div>
          </span>
        </li>
      );
    });

    return (
      <section id="skills">
        <div className="col-md-12">
          <div className="col-md-12">
            <h1 className="section-title">
              <span className="text-white">{sectionName}</span>
            </h1>
          </div>
          <div className="col-md-12 text-center">
            <ul className="list-inline mx-auto skill-icon">{skills}</ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
