import React, { Component } from "react";

class Footer extends Component {
  render() {
    let networkList = [{
      "name": "github",
      "url": "https://github.com/karanarora026",
      "class": "fab fa-github"
    },
    {
      "name": "linkedIn",
      "url": "https://www.linkedin.com/in/karanarora026/",
      "class": "fab fa-linkedin"
    }];
    var networks = networkList.map(function (network) {
      return (
        <span key={network.name} className="m-4">
          <a href={network.url} target="_blank" rel="noopener noreferrer">
            <i className={network.class}></i>
          </a>
        </span>
      );
    });


    return (
      <footer>
        <div className="col-md-12">
          <div className="social-links">{networks}</div>

          <div className="copyright py-4 text-center">
            <div className="container">
              <small>
                Copyright &copy;{" "}
                {"Karan Arora"}
              </small>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
