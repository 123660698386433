import React, { Component } from "react";
import { Icon } from "@iconify/react";
import awsIcon from "@iconify/icons-logos/aws";
import javascriptIcon from "@iconify/icons-logos/javascript";
import nodeJsIcon from "@iconify/icons-logos/nodejs";
import reactIcon from "@iconify/icons-logos/react";

class About extends Component {
  render() {
    var profilepic = "images/profile.jpeg";
    var sectionName = "ABOUT ME";
    return (
      <section id="about">
        <div className="col-md-12">
          <h1 style={{ color: "black" }}>
            <span>{sectionName}</span>
          </h1>
          <div className="row center mx-auto mb-5">
            <div className="col-md-4 mb-5 center">
              <div className="polaroid">
                <span style={{ cursor: "auto" }}>
                  <img
                    height="250px"
                    src={profilepic}
                    alt="Avatar placeholder"
                  />
                  <Icon
                    icon={awsIcon}
                    style={{ fontSize: "250%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={javascriptIcon}
                    style={{ fontSize: "250%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={nodeJsIcon}
                    style={{ fontSize: "250%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={reactIcon}
                    style={{ fontSize: "250%", margin: "9% 5% 0 5%" }}
                  />
                </span>
              </div>
            </div>

            <div className="col-md-8 center">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <span
                      className="iconify"
                      data-icon="emojione:red-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:yellow-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:green-circle"
                      data-inline="false"
                    ></span>
                  </div>
                  <div
                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                    style={{
                      height: "auto",
                      fontSize: "132%",
                      lineHeight: "200%",
                    }}
                  >
                    <br />
                    <span className="wave">Hi :) </span>
                    <br />
                    <br />
                    👋 I'm Karan Arora.
                    <br />
                    Proficient in JavaScript, TypeScript, AWS, Node.js, Python, React, Angular, HTML,CSS, Testing Frameworks/tools- Jest, Mocha, Chai, Sinon, React Testing Library, Cypress etc.
                    Always interested in learning new skills.
                    <br />
                    Having around 5 years of work experience implementing web applications- Frontend & Backend. I’ve always been a great problem solver, an independent introvert, and a technophile obsessed with the latest devices. I have worked on multiple projects with diverse teams developing finance and health business products.
                    <br />
                    Currently, I’m working as a Full-stack Software Engineer with the amazing team at Fidelity International and am getting into AWS & serverless architecture on my own time.
                    <br />
                    Feel free to contact me at &nbsp;
                    <span style={{ color: "#0365bb", cursor: "pointer" }}
                      onClick={() => window.location = 'mailto:karanarora026@gmail.com'}>
                      karanarora026@gmail.com
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
